import { render, staticRenderFns } from "./otherChip.vue?vue&type=template&id=023781c5&scoped=true&"
import script from "./otherChip.vue?vue&type=script&lang=js&"
export * from "./otherChip.vue?vue&type=script&lang=js&"
import style0 from "./otherChip.vue?vue&type=style&index=0&id=023781c5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "023781c5",
  null
  
)

export default component.exports