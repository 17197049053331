<template>
    <div class="productContainer" v-loading="loading">
        <!-- 面包屑 -->
        <div class="bread">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item class="p_bread"><a href="https://www.wch.cn/">首页</a></el-breadcrumb-item>
                <el-breadcrumb-item class="p_bread"  :to="{ path: '/products/productsCenter', query:{categoryId:'68'} }"><a>产品中心</a></el-breadcrumb-item>
                <el-breadcrumb-item class="p_bread" :to="{ path: '/products/productsCenter', query:{categoryId:'68'} }"><a>接口芯片</a></el-breadcrumb-item>
                <el-breadcrumb-item>其他芯片</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="main" >
            <!-- 标题 -->
            <!-- <div class="title">{{content.name}}</div> -->
            <!-- 产品概述 -->
            <div class="overview module">
                <div class="overviewTitle moduleTitle">产品概述</div>
                <div class="overviewContent moduleContent" v-html="content.productOverview"></div>
            </div>
            <!-- 产品阵容 -->
            <div class="lineup module" v-if="content.img">
                <div class="lineupTitle moduleTitle">产品阵容</div>
                <div class="lineupContent moduleContent">
                    <img style="width:65%"  :src="content.img" alt="">
                </div>
            </div>
            <!-- 产品选型 -->
            <div class="lectotype module" v-if="content.productList&&content.productList.length">
                <div class="lectotypeTitle moduleTitle">产品选型</div>
                <div v-for="(items,index) in content.productList" :key="index">
                    <div class="tabTitle"  :id="course(index)">{{ items.name }}</div>
                    <div class="lectotypeContent moduleContent">
                        <!-- 单表格 -->
                        <div v-if="items.productTables.length == 1">
                            <el-table :data="items.productTables[0].value" style="min-width:150px;" :max-height="isMoPhone?500:pcTableMaxHeight" border :header-cell-style='getHeadClass'>
                                <el-table-column :fixed='index==0 && !isMoPhone ? true: null'  v-for="(item,index) in items.productTables[0].meta" :key="index" 
                                :label="item.title" :property="item.field" :align="index==0?'center':item.align" :width="index==0?95:(isMoPhone&&(item.field=='td'||item.field=='gn')?220:item.width)">
                                    <template slot-scope="scope">
                                        <span v-if="item.head==1">
                                            <a v-if="scope.row.url" style="color:#337ab7; font-size:14px; cursor:pointer; text-align:center;" :href="scope.row.url" target="_blank" >{{scope.row[scope.column.property]}}</a>
                                            <span v-else style="font-size:16px;">
                                                {{scope.row[scope.column.property]}}
                                            </span>
                                        </span>
                                        <span v-else-if="scope.column.property=='td' && scope.row.td">
                                            <div style="text-align:left;" v-html="scope.row.td"></div>
                                        </span>
                                        <span v-else >
                                            <span v-if="scope.row[scope.column.property]" v-html="scope.row[scope.column.property]"></span>
                                            <span v-else>-</span>
                                        </span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div> 
                        <!-- 多表格 -->
                        <el-tabs type="border-card" v-if="items.productTables.length > 1" v-model="activeTab" @tab-click="handleTabClick">
                            <el-tab-pane  v-for="(item,index) in items.productTables" :key="index" :name="String(item.title)">
                                <template slot="label">
                                    <span class="tabLabelClass"></span>{{item.title}}
                                </template>
                                <el-table  :data="item.value" style="min-width:150px;overflow: hidden;" :max-height="isMoPhone?500:pcTableMaxHeight"  border :header-cell-style='getHeadClass'>
                                    <el-table-column :fixed='i==0 && !isMoPhone ? true: null'  v-for="(t,i) in item.meta" :key="i" 
                                    :label="t.title" :property="t.field" :align="i==0?'center':t.align" :width="i==0?95:(isMoPhone&&(t.field=='td'||t.field=='gn')?220:t.width)">
                                        <template slot-scope="scope">
                                            <span v-if="t.head==1">
                                                <a v-if="scope.row.url" style="color:#337ab7; font-size:14px; cursor:pointer; text-align:center;" :href="scope.row.url" target="_blank" >{{scope.row[scope.column.property]}}</a>
                                                <span v-else style="font-size:16px;">
                                                    {{scope.row[scope.column.property]}}
                                                </span>
                                            </span>
                                            <span v-else-if="scope.column.property=='td' && scope.row.td">
                                                <div style="text-align:left;" v-html="scope.row.td"></div>
                                            </span>
                                            <span v-else >
                                                <span v-if="scope.row[scope.column.property]" v-html="scope.row[scope.column.property]"></span>
                                                <span v-else>-</span>
                                            </span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
</template>
<script>
import { otherChip }  from '@/services/product.js'
export default {
  name: "otherChip",
  data() {
    return {
        loading:false,
        content:{},
        categoryId:'',
        activeTab:'',
        windowWidth:window.innerWidth,
        isMoPhone:false,
        pcTableMaxHeight:600,
        url:{
          contentUrl:'/api/official/website/productTables/productOtherChip'
        }
    };
  },
  computed: {
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      if (newWidth <= 768) {
        this.isMoPhone = true
      }else{
        this.isMoPhone = false
      }
      
    },
    $route(to) {// 对路由参数变化作出响应，进行锚点定位
        if(to.name == 'OtherChip' ){//排除其他页面
            console.log('tName',to.query.tName)
            if (to.query.tName) {
                this.goToTab(to.query.tName)//设置锚点定位  
            } else {
                setTimeout(() => {
                    window.scrollTo({ top: 0, behavior: "smooth", });
                }, 1);
            }
        }
    }
  },
  mounted() {
    if (this.windowWidth <= 640) {
        this.isMoPhone = true
    }else{
        this.isMoPhone = false
    }
  },
  activated(){
    if(this.$route.query.categoryId){
        this.categoryId = this.$route.query.categoryId
        this.$emit('giveCategoryId',this.categoryId)//将当前激活侧边菜单传递给父组件
        this.getContent_1()
    }
    console.log('可视高度----',window.innerHeight)
    if(window.innerHeight<=700 && window.innerHeight>600){
        this.pcTableMaxHeight = 650
    }else if(window.innerHeight<=600){
        this.pcTableMaxHeight = 600
    }else{
        this.pcTableMaxHeight = 800
    }
  },
  methods:{
    //获取页面内容
    getContent_1(){
      otherChip({categoryId:this.categoryId}).then(res => {
          this.loading = true;
          console.log('otherRes',res)
          if(res.data.code===0 && res.data.data){
            this.content = res.data.data
            document.title = this.content.name+' - 南京沁恒微电子股份有限公司'
            this.content.productList.forEach(item=>{
                item.productTables.forEach(items=>{
                    items.meta = JSON.parse(items.meta)
                    items.value = JSON.parse(items.value)
                })
            })
            console.log('表格数据--',this.content.productList)
            if (this.$route.query.tName) {
                this.activeTab = this.content.productList[0].productTables[0].title
                if (this.$route.query.tName == '控制类芯片' || this.$route.query.tName == '转接类芯片') {
                    this.goToTab('PCI/PCIe')
                    if (this.$route.query.tName == '控制类芯片') {
                        this.activeTab = this.content.productList[0].productTables[1].title
                    }
                } else {
                    this.goToTab(this.$route.query.tName)//设置锚点定位  
                }
            }else{
                this.activeTab = this.content.productList[0].productTables[0].title
                setTimeout(() => {
                    window.scrollTo({ top: 0, behavior: "smooth", });
                }, 1);
            }
        }else{
            this.$message.error(res.data.message);
        }
        this.loading = false;
      });
    },
    openSite(index){
        if (index == 1) {
            window.open('https://www.wch.cn/')
        }
        if (index == 2) {
            this.$router.push({ path: '/products/productsCenter', query: {categoryId:5}})
        }
        if (index == 3) {
            this.$router.push({ path: '/products/productsCenter', query: {categoryId:68}})
        }
    },
    course(id){
        return "course"+id;
    },
    goToTab(name){
        let index = ''
        for (let i = 0; i < this.content.productList.length; i++) {
            if (name == this.content.productList[i].name) {
                index = '#course'+i
                break
            }
        }
        setTimeout(() => {
            const Interface = document.querySelector(index);
            const offsetTop = Interface.offsetTop;
            window.scrollTo({
                top: offsetTop,
                behavior: "smooth",
            });
        }, 1);
    },
    //自定义表头样式
    getHeadClass({rowIndex}){
        if(rowIndex==0){
          return 'background-color:#f2f2f2;color:#666;font-size:16px;text-algin:center;width:170px;'
        }else{
          return "color:#666; font-size:14px;text-algin:center; background-color:#fff;"
        }
    },
    //切换tab
    handleTabClick(e){
        console.log(e)
        this.activeTab = e.name
        console.log(e.name)
        /* let newUrl = this.changeURLParam(window.location.href, 'tName', this.activeTab)//地址栏参数也要变化
        history.replaceState(null, null, newUrl);  // 替换地址栏 */
    },
    //修改地址栏参数
    changeURLParam(url, type, value) {
        let reg = eval('/([\?|&]' + type + '=)[^&]*/gi');
        value = value.toString().replace(/(^\s*)|(\s*$)/g, ""); // 移除首尾空格
        let url2 = '';
        if (!value) { // remove
            url2 = url.replace(reg, '');
        } else {
            if (url.match(reg)) { // edit
                url2 = url.replace(reg, '$1' + value);
            } else { // add
                url2 = url + (url.indexOf('?') > -1 ? '&' : '?') + type + '=' + value;
            }
        }
        // console.log('url2--',url2)
        return url2;
    },
  }
};
</script>
<style scoped>
 .productContainer{
    width: 100%;
    text-align: left;
 }
 .bread{
    display: flex;
    font-size: 18px;
 }
 .p_bread .el-breadcrumb__inner a, /deep/.el-breadcrumb__inner.is-link{
    color: #337ab7;
 }
 .overview{
    margin-top: 20px;
 }
 .main .title{
    font-size: 28px;
    color: #002c85;
    font-weight: 600;
    margin: 50px 0px;
    letter-spacing: 0px;
 }
 .main .moduleTitle{
    font-size: 22px;
    color: #002c85;
    font-weight: 600;
    letter-spacing: 0px;
    margin-bottom: 20px;
 }
 .tabTitle{
    color: #1ea9fb;
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    border-bottom:2px solid #ccc;
    margin-bottom: 20px;
 }
 .main .moduleContent{
    color:#000;
    letter-spacing: 0px;
    margin-bottom:20px;
 }
 .main .lineupContent{
    display: flex;
    justify-content: center;
 }
 .main .lectotypeContent {
    width: 100%;
 }
 .main .lectotypeContent .el-table{
    font-size: 14px !important;
 }
 .lectotypeContent /deep/ .el-table th.el-table_4_column_24{
    width: 170px; 
 }
 .lectotypeContent /deep/.el-table th.el-table__cell>.cell{
    letter-spacing: 0px;
    /* font-weight: 500; */
    font-size: 14px;
 } 
 .lectotypeContent .el-table td.el-table__cell, /deep/.el-table th.el-table__cell.is-leaf{
    border-bottom: 1px solid #dfdfdf;
 }
 .lectotypeContent /deep/.el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed{
    border-right: 1px solid #dfdfdf;
    padding: 4px 0px;
 }
 .lectotypeContent /deep/.el-table--border, .el-table--group{
    border: 1px solid #e1e1e1;
 }
 .lectotypeContent /deep/.el-tabs--border-card>.el-tabs__content{ 
    padding: 15px 10px;
 }
 .lectotypeContent /deep/.el-tabs__item{
    font-size: 16px;
    font-weight: 700;
 }
 .lectotypeContent /deep/.el-tabs--border-card>.el-tabs__header .el-tabs__item{
    color: #333;
 }
 .lectotypeContent /deep/.el-tabs--border-card>.el-tabs__header{
    background-color: #f2f2f2;
 }
 .lectotypeContent /deep/.el-table--border th.el-table__cell.gutter:last-of-type {
    border-right: none;
    border-bottom: none;
 }
 .lectotypeContent .tabLabelClass{
    display: inline-block;
    width: 5px;
    height: 5px;
    margin-bottom: 3px;
    background: #000;
    border-radius: 50%;
    margin-right: 10px;
 }
 .lectotypeContent /deep/ .el-table{
    box-sizing: unset;
 }
 .el-breadcrumb__item{
    margin-bottom: 5px;
}
@media screen and (max-width: 688px) {
    .main .lectotypeContent {
        width: 110%;
        margin-left: -5%;
    }
    .lectotypeContent /deep/.el-tabs--border-card>.el-tabs__content{ 
        padding: 10px 4px;
    }
}
</style>